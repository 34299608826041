import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import AuthLayout from '~/layouts/auth';
import DefaultLayout from '~/layouts/default';
import paths from '~/routes/paths';
import { AuthContext } from '~/store/contexts/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

export default function RouteWrapper({ component: Component, isPrivate = false, permissions = null, ...rest }) {
  const { authenticated, isLoading } = useContext(AuthContext);

  if (!authenticated && isPrivate && !isLoading) {
    return <Redirect to={paths.login} />;
  } else if (authenticated && !isPrivate && !isLoading) {
    return <Redirect to={paths.dashboard} />;
  }

  const Layout = authenticated ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
          <ToastContainer />
        </Layout>
      )}
    />
  );
}
