import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarName = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  h3,
  h5 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: right;
  }

  h3 {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.01em;
  }

  h5 {
    font-size: 15px;
    line-height: 18px;
  }
`;
