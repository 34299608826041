import React, { createContext } from 'react';

import useMenu from './hooks/useMenu';

interface Menu {
  drawerWidth: number;
  isOpenMenu: boolean;
  isMobile: boolean;
  selectedMenuOption: string;
  handleToggleMenu: (status: boolean) => void;
  handleSelectMenuOption: (option: string) => void;
}

const MenuContext = createContext<Menu>({} as Menu);

const MenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { drawerWidth, isOpenMenu, selectedMenuOption, isMobile, handleToggleMenu, handleSelectMenuOption } = useMenu();

  return (
    <MenuContext.Provider
      value={{ drawerWidth, isOpenMenu, isMobile, selectedMenuOption, handleToggleMenu, handleSelectMenuOption }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };
