import React, { useContext } from 'react';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import { IconButton, useTheme, Toolbar, Typography, Badge } from '@mui/material';

import { MenuContext } from '~/store/contexts/MenuContext';

import { Notification } from './Notification';
import { Profile } from './Profile';
import { AppBar } from './styles';

export const Header = () => {
  const theme = useTheme();

  const { drawerWidth, isOpenMenu, handleToggleMenu } = useContext(MenuContext);

  return (
    <AppBar position='fixed' color='inherit' open={isOpenMenu} drawerWidth={drawerWidth} theme={theme} elevation={0}>
      <Toolbar
        sx={{
          pr: '24px',
          color: '#3134BA',
        }}
      >
        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={() => handleToggleMenu(!isOpenMenu)}
          sx={{
            marginRight: '0.1vw',
          }}
        ></IconButton>

        <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}></Typography>
        <IconButton size='small' aria-label='show notifications' color='inherit'>
          <Badge color='error'>
            <TranslateOutlinedIcon sx={{ color: theme.palette.primary.main }} />
          </Badge>
        </IconButton>
        <IconButton size='small' aria-label='show notifications' color='inherit'>
          <Badge color='error'>
            <DarkModeOutlinedIcon sx={{ color: theme.palette.primary.main }} />
          </Badge>
        </IconButton>
        <Notification
          icon={<NotificationsOutlinedIcon sx={{ color: theme.palette.primary.main }} />}
          notificationCount={0}
        />
        <Profile />
      </Toolbar>
    </AppBar>
  );
};
