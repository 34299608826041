import { useEffect, useState } from 'react';

import api from '@api';

// interface AuthLoginData {
//   email: string;
//   password: string;
//   remember_me: boolean;
// }

// const useAuth = () => {
//   const [user, setUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const storagedUser = localStorage.getItem('@goodjob:user');
//     const storagedToken = localStorage.getItem('@goodjob:accessToken');

//     if (storagedUser && storagedToken && !user) {
//       setUser(JSON.parse(storagedUser));
//     }
//     if (user && !storagedToken) {
//       handleLogout();
//     }
//   });

//   useEffect(() => {
//     setIsLoading(false);
//   }, [user]);

//   const handleEmailLogin = async ({ email, password, remember_me }: AuthLoginData) => {
//     const { accessToken, refreshToken } = await api.auth.login({ email, password, remember_me });

//     const responseUserData = await api.users.me({
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     localStorage.setItem('@goodjob:authType', 'email');
//     localStorage.setItem('@goodjob:accessToken', accessToken);
//     localStorage.setItem('@goodjob:refreshToken', refreshToken);
//     localStorage.setItem('@goodjob:user', JSON.stringify(responseUserData));
//     setUser(responseUserData);
//   };

//   const handleLogout = () => {
//     localStorage.clear();
//     setUser(null);
//   };

//   return { user, handleEmailLogin, handleLogout, isLoading };
// };

// export default useAuth;
const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('@goodjob:user');
    const storedToken = localStorage.getItem('@goodjob:accessToken');

    if (storedUser && storedToken && !user) {
      setUser(JSON.parse(storedUser));
    }

    if (user && !storedToken) {
      handleLogout();
    }
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [user]);

  const handleEmailLogin = async ({ email, password, remember_me }) => {
    const { accessToken, refreshToken } = await api.auth.login({ email, password, remember_me });

    const userData = await api.users.me({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    localStorage.setItem('@goodjob:authType', 'email');
    localStorage.setItem('@goodjob:accessToken', accessToken);
    localStorage.setItem('@goodjob:refreshToken', refreshToken);
    localStorage.setItem('@goodjob:user', JSON.stringify(userData));
    setUser(userData);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
  };

  return { user, isLoading, handleEmailLogin, handleLogout };
};

export default useAuth;
