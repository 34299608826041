import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, useTheme } from '@mui/material';

import { FormInputText } from '@components/FormInputText';
import { Notify } from '@components/Notify';

import api from '~/api';
import paths from '~/routes/paths';
import { LoadingContext } from '~/store/contexts/LoadingContext';

const REQUIRED_FIELD_ERROR = 'Campo obrigatório não preenchido.';

const schema = Yup.object()
  .shape({
    email: Yup.string().email('Informe um e-mail válido.').required(REQUIRED_FIELD_ERROR),
  })
  .required();

export default function FormRequestNewPassword() {
  const theme = useTheme();
  const history = useHistory();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendFormRequestNewPassword = async (formData: any) => {
    showLoading();
    try {
      await api.account.forgotPassword(formData.email);
      Notify.success({ text: 'Foi enviado um link para redefinição de senha para seu email' });
      history.push(paths.login);
    } catch (err) {
      Notify.warning({ text: err.message });
    } finally {
      hideLoading();
    }
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(sendFormRequestNewPassword)} sx={{ mt: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <FormInputText
            control={control}
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
            size='medium'
            error={!!errors.email}
            helperText={!!errors.email && errors.email.message}
          />
          <Grid />
        </Grid>
      </Grid>
      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        size='large'
        disabled={isLoading}
        loading={isLoading}
        sx={{
          mt: 5,
          mb: 4,
          textTransform: 'none',
          fontSize: '12pt',
          ':hover': { background: theme.palette.secondary.main },
          height: '54px',
        }}
      >
        Enviar Link de Redefinição
      </LoadingButton>
    </Box>
  );
}
