import { styled, Theme, CSSObject, Drawer as MuiDrawer, List as MuiList, Divider as MuiDivider } from '@mui/material';

interface CustomDrawerProps {
  open: boolean;
  drawerWidth: number;
}

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: '.25s',
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: '.25s',
  }),
  overflowX: 'hidden',
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: `${drawerWidth}px`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})<CustomDrawerProps>(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  boxShadow: '3px 0px 6px #00000029',
  zIndex: 1205,
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': closedMixin(theme, drawerWidth),
  }),
}));

export const List = styled(MuiList, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})<CustomDrawerProps>(({ theme, open, drawerWidth }) => ({
  paddingTop: 0,
  '& .MuiButtonBase-root': {
    width: '90%',
    borderRadius: '0px 50px 50px 0px',
    background: theme.palette.common.white,
    transition: 'all .2s ease',
  },
  '& .MuiButtonBase-root:hover': {
    // background: theme.palette.secondary.main,
    background: `linear-gradient(70.35deg, rgba(58, 208, 255, 0.8) 10.42%, ${theme.palette.secondary.main} 95.34%)`,
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
    '& .MuiListItemIcon-root': {
      '& svg': {
        color: theme.palette.common.white,
      },
    },
  },
  '& .MuiTypography-root': {
    color: theme.palette.primary.main,
    fontFamily: 'arial',
  },
  '& .MuiListItem-root': {
    height: '50px',
    ...(!open && {
      [theme.breakpoints.up('sm')]: {
        height: `42px`,
      },
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiListItemText-root': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
        flex: 'unset !important',
        '& .MuiTypography-root': {
          fontWeight: 500,
          fontSize: '11px',
          lineHeight: '13px',
        },
      },
    }),
  },
  '& .MuiListItemIcon-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(!open && {
      paddingLeft: '10%',
    }),
    '& svg': {
      color: theme.palette.primary.main,
      ...(!open && {
        fontSize: `25px`,
        [theme.breakpoints.up('sm')]: {
          fontSize: `22px`,
        },
      }),
    },
  },
}));

export const Divider = styled(MuiDivider)({
  margin: '22px 28px',
});
