import axios from './api';

export default {
  async signUp(data: { name: string; email: string; password: string }) {
    try {
      const response = await axios.post(`/api/v1/sign-up`, data);
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.status === 409) {
        throw new Error('Ops, já existe um usuário com este email no sistema!');
      } else {
        throw new Error(err.response.message);
      }
    }
  },
  async changePassword(data: { old_password: string; password: string }) {
    const response = await axios.post(`/api/v1/account/change-password`, data);
    return response.data;
  },
  async recoveryPassword(data: { token: string; password: string; password_confirmation: string }) {
    const response = await axios.post(`/api/v1/account/recovery-password`, data);
    return response.data;
  },
  async forgotPassword(email: string) {
    try {
      const response = await axios.post(`/api/v1/account/forgot-password`, { email });
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.status == 404) {
        throw new Error('Ops, email não encontrado no sistema');
      } else {
        throw new Error('Ops ocorreu um erro, tente novamente');
      }
    }
  },
  async verifyEmail(token: string) {
    const res = await axios.post('/api/v1/account/confirm-account', { token });
    return res.data;
  },
};
