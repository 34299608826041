import React from 'react';

export const StatusIndicator = ({ status }) => {
  return (
    <div
      style={{
        backgroundColor: status ? '#EAF9E0' : '#F1F1F2',
        borderRadius: 15,
        color: status ? '#78D534' : '#8F9197',
        justifyContent: 'center',
        width: '80%',
      }}
    >
      {status ? 'Ativo' : 'Inativo'}
    </div>
  );
};
