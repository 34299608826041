import React, { useContext, useState } from 'react';

import {
  PersonOutlineOutlined as PersonIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  LogoutOutlined as LogoutOutlinedIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Divider,
  MenuList,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import userImage from '@assets/user-round.svg';

import { AuthContext } from '~/store/contexts/AuthContext';

import { AvatarContainer, AvatarName } from './styles';

export const Profile = () => {
  const theme = useTheme();

  const { handleLogout, user } = useContext(AuthContext);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    handleLogout();
  };

  return (
    <>
      <AvatarContainer>
        <Tooltip title='Perfil'>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt='Avatar' src={userImage} />
          </IconButton>
        </Tooltip>
      </AvatarContainer>
      <Menu
        sx={{
          mt: '7vh',
          '.MuiPaper-root': {
            top: '0 !important',
          },
          '& .MuiButtonBase-root:hover': {
            background: '#E7E6E8',
          },
          '& .MuiTypography-root': {
            color: 'gray',
          },
          '& svg': {
            marginRight: '12px',
            fill: 'gray',
          },
        }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Paper sx={{ width: 240, maxWidth: '100%' }}>
          <MenuList>
            <MenuItem onClick={handleCloseUserMenu}>
              <Avatar alt='Avatar' src={userImage} />
              <Typography
                component='div'
                textAlign='left'
                color='black'
                sx={{
                  paddingLeft: '15px',
                }}
              >
                <div style={{ color: 'black', fontSize: '12pt', fontWeight: `bold` }}>
                  {user.name.split(',')[0] || ''}
                </div>
                <div style={{ color: 'gray', fontSize: '8pt' }}>Admin</div>
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCloseUserMenu}>
              <PersonIcon />
              <Typography textAlign='center'>Perfil</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCloseUserMenu}>
              <SettingsOutlinedIcon />
              <Typography textAlign='center'>Configurações</Typography>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <HelpOutlineOutlinedIcon />
              <Typography textAlign='center'>FAQ</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logoutUser}>
              <LogoutOutlinedIcon />
              <Typography textAlign='center'>Sair</Typography>
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>
    </>
  );
};
