import React, { useContext, FC } from 'react';

import { useTheme } from '@mui/material';

import { MenuContext } from '~/store/contexts/MenuContext';

import { Footer } from '../Footer';
import { MenuContent } from './MenuContent';
import { MenuToolbar } from './MenuToolbar';
import { Drawer as StyledDrawer } from './styles';

interface Props {
  window?: () => Window;
}

export const Menu: FC<Props> = ({ window }) => {
  const theme = useTheme();
  const { drawerWidth, isOpenMenu, handleToggleMenu } = useContext(MenuContext);

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <StyledDrawer
        container={container}
        drawerWidth={drawerWidth}
        sx={{
          '& .MuiDrawer-paper': {
            background: theme.palette.common.white,
          },
        }}
        onClose={() => handleToggleMenu(false)}
        variant='permanent'
        open={isOpenMenu}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <MenuToolbar />
        <MenuContent />

        {isOpenMenu && <Footer />}
      </StyledDrawer>
    </>
  );
};
