import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, FormControlLabel, Checkbox, Link, Box, Grid, useTheme } from '@mui/material';

import { FormInputText } from '@components/FormInputText';
import { Notify } from '@components/Notify';

import paths from '~/routes/paths';
import { AuthContext } from '~/store/contexts/AuthContext';
import { LoadingContext } from '~/store/contexts/LoadingContext';

const REQUIRED_FIELD_ERROR = 'Campo obrigatório não preenchido.';

const schema = Yup.object()
  .shape({
    email: Yup.string().email('Informe um e-mail válido.').required(REQUIRED_FIELD_ERROR),
    password: Yup.string().required(REQUIRED_FIELD_ERROR),
  })
  .required();

export default function EmailLogin() {
  const theme = useTheme();
  const { handleEmailLogin } = useContext(AuthContext);
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const [remember, setRemember] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendFormLogin = async (formData: any) => {
    try {
      showLoading();
      await handleEmailLogin({ ...formData, remember });
    } catch (err) {
      Notify.error({ text: err.message });
    } finally {
      hideLoading();
    }
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(sendFormLogin)} sx={{ mt: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <FormInputText
            control={control}
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
            size='medium'
            error={!!errors.email}
            helperText={!!errors.email && errors.email.message}
          />
          <Grid />
          <Grid item xs={12}>
            <FormInputText
              control={control}
              margin='normal'
              required
              fullWidth
              name='password'
              label='Senha'
              type='password'
              id='password'
              autoComplete='current-password'
              size='medium'
              error={!!errors.password}
              helperText={!!errors.password && errors.password.message}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='baseline'>
        <Grid xs={6} item>
          <FormControlLabel
            control={
              <Checkbox value='remember' color='primary' checked={remember} onChange={() => setRemember(!remember)} />
            }
            label='Lembrar-me'
          />
        </Grid>
        <Grid xs={6} item>
          <Box sx={{ textAlign: 'right' }}>
            <Link
              href={paths.requestNewPassword}
              underline='always'
              sx={{
                fontSize: '10pt',
                fontWeight: 'lighter',
              }}
            >
              Esqueceu sua senha?
            </Link>
          </Box>
        </Grid>
      </Grid>
      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        size='large'
        disabled={isLoading}
        loading={isLoading}
        sx={{
          mt: 1,
          mb: 2,
          textTransform: 'none',
          fontSize: '24px',
          fontWeight: 500,
          ':hover': { background: theme.palette.secondary.main },
          height: '54px',
          marginBottom: '27px',
        }}
      >
        Entrar
      </LoadingButton>
    </Box>
  );
}
