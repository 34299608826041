import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html,
  body,
  #root {
    height: 100%;
    background: #FFFFF;
  }
  
  body,
  input,
  button, a {
    font: 14px 'Inter', sans-serif;
    font-weight: 600;
  }

  * {
    -webkit-font-smoothing: antialiased;
  }
`;

export default GlobalStyle;
