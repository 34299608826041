import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#3134BA',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00A6DD',
      contrastText: '#fff',
    },
  },
});
