import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Box,
  useTheme,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material';

import api from '@api';

import { FormInputText } from '@components/FormInputText';
import { Notify } from '@components/Notify';
import { Switch } from '@components/Switch';
import { UploadImage } from '@components/UploadImage';

const DEFAULT_ERROR_MESSAGE = 'Campo obrigatório não preenchido.';

const schema = Yup.object()
  .shape({
    name: Yup.string().required(DEFAULT_ERROR_MESSAGE),
    description: Yup.string().required(DEFAULT_ERROR_MESSAGE),
  })
  .required();

export function StoreCategory({ category, closeModal, loadCategories }) {
  const theme = useTheme();
  const [status, setStatus] = useState(true);
  const [fileError, setFileError] = useState(false);
  const [file, setFile] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setFileError(false);
  }, [file]);

  useEffect(() => {
    if (category) {
      reset({
        name: category.name,
        image: category.image,
        description: category.description,
      });
      setStatus(category.is_active);
    }
  }, [category]);

  const sendForm = async (dataForm: any) => {
    if (!file && !category) {
      setFileError(true);
      return;
    }
    try {
      const formData = new FormData();

      if (category) formData.append('id', category.id);

      Object.keys(dataForm).forEach((key) => {
        formData.append(key, dataForm[key]);
      });

      if (file) formData.append('image', file);

      formData.append('is_active', status.toString());

      await api.categories.storeCategory(formData);
      Notify.success({ text: 'Categoria foi criada!' });
      loadCategories();
      closeModal();
    } catch (err) {
      Notify.error({ text: err.message });
    }
  };

  return (
    <>
      <DialogContent>
        <Box
          component='form'
          noValidate
          onSubmit={handleSubmit(sendForm)}
          sx={{ '& .MuiFormControl-root': { marginY: 1.5 } }}
        >
          <FormInputText
            control={control}
            required
            fullWidth
            label='Nome'
            name='name'
            size='medium'
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
          />
          <UploadImage title='Adicionar Imagem da Categoria' file={file} setFile={setFile} />
          {fileError && (
            <FormHelperText sx={{ marginLeft: 2, color: '#d32f2f' }}>
              É obrigatório o upload de uma imagem
            </FormHelperText>
          )}
          <FormInputText
            control={control}
            required
            fullWidth
            label='Descrição'
            name='description'
            size='medium'
            multiline
            rows={4}
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
          />
          <FormControl sx={{ padding: 1 }}>
            <FormLabel sx={{ mb: 1, fontSize: '1rem' }}>Status</FormLabel>
            <Switch onChange={() => setStatus(!status)} checked={status} />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleSubmit(sendForm)} color='success' variant='contained'>
          Salvar
        </Button>
      </DialogActions>
    </>
  );
}
