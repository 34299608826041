import React from 'react';
import { useContext } from 'react';

import { Typography } from '@mui/material';

import { MenuContext } from '~/store/contexts/MenuContext';

import { Footer as StyledFooter } from './styles';

export const Footer = () => {
  const { isOpenMenu, isMobile } = useContext(MenuContext);
  return (
    <StyledFooter isOpenMenu={isOpenMenu} isMobile={isMobile}>
      <Typography component='p'>Copyright © 2022. Todos os direitos reservados.</Typography>
    </StyledFooter>
  );
};
