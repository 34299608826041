import React, { FC, MouseEvent, ChangeEvent, ReactElement } from 'react';

import { Box, TableContainer, Table, TablePagination, Stack, Pagination } from '@mui/material';

import { tableStyles } from './styles';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';

export interface Column {
  name: string;
  header?: string;
  align?: 'left' | 'center' | 'right';
  disablePadding?: boolean;
  sortable?: boolean;
  custom?: (data: any) => ReactElement | string;
}

export type Order = 'asc' | 'desc';

interface Data {
  description: string;
  client_name: string;
  budget_type: string;
  createdBy: string;
  start_date: string;
  actions: boolean;
}

interface Props {
  keyExtractor?: string;
  tableStyle?: 'normal' | 'spaced';
  toolbar?: ReactElement | (() => ReactElement);
  columns: Array<Column>;
  actions?: boolean;
  customActions?: unknown;
  templateActions?: Array<string>;
  actionHeader?: string | false;
  defaultHandleActions?: {
    view?: (row: unknown) => void;
    edit?: (row: unknown) => void;
    delete?: (row: unknown) => void;
  };
  data: Array<any>;
  page?: number;
  rowsPerPage?: Array<number>;
  pageSize?: number;
  order?: Order;
  orderBy?: string;
  pagination?: boolean;
  idItemData?: string;
  baseURL?: string;
  total?: number;
  changePage?: (newPage: number) => void;
  changePageSize?: (size: number) => void;
  changeOrder?: (order: Order) => void;
  changeOrderBy?: (field: string) => void;
}

const ROWS_PER_PAGE = [5, 10, 15];
const DEFAULT_ACTIONS_TEMPLATE = ['view', 'edit', 'delete'];

export const DataGrid: FC<Props> = ({
  keyExtractor = 'id',
  tableStyle = 'normal',
  page,
  changePage,
  pageSize,
  rowsPerPage = ROWS_PER_PAGE,
  order,
  orderBy,
  pagination = true,
  total,
  changePageSize,
  changeOrder,
  changeOrderBy,
  columns,
  actions,
  actionHeader,
  idItemData = 'id',
  baseURL = ' ',
  data,
  customActions,
  templateActions = DEFAULT_ACTIONS_TEMPLATE,
  defaultHandleActions,
  toolbar,
}) => {
  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';

    if (!changeOrder && !changeOrderBy) return;

    changeOrder(isAsc ? 'desc' : 'asc');
    changeOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    changePage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    changePageSize(parseInt(event.target.value, 10));
    changePage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * pageSize - total) : 0;

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
  };

  return (
    <Box>
      {typeof toolbar === 'function' ? toolbar() : toolbar}
      <TableContainer>
        <Table sx={tableStyles({ tableStyle })} aria-labelledby='tableTitle' size='medium'>
          <TableHead
            actions={actions}
            actionHeader={actionHeader}
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody
            keyExtractor={keyExtractor}
            tableStyle={tableStyle}
            actions={actions}
            customActions={customActions}
            templateActions={templateActions}
            defaultHandleActions={defaultHandleActions}
            columns={columns}
            data={data}
            emptyRows={emptyRows}
          />
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPage}
          component='div'
          count={total}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Itens por página'
          labelDisplayedRows={labelDisplayedRows}
        />
      )}
    </Box>
  );
};
