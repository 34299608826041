import React from 'react';
import { Router, Switch } from 'react-router-dom';

import { Categories } from '@pages/Categories';
import { Dashboard } from '@pages/Dashboard';
import { LoginPage } from '@pages/Login';
import { RecoveryPassword } from '@pages/RecoveryPassword/RecoveryPassword';
import { RequestNewPassword } from '@pages/RecoveryPassword/RequestNewPassword';
import { Services } from '@pages/Services';
import { SignUp } from '@pages/SignUp';

import { Users } from '~/pages/Users';

import history from './history';
import paths from './paths';
import Route from './Route';

export const RoutePages = () => (
  <Router history={history}>
    <Switch>
      <Route path='/' exact component={Dashboard} isPrivate />
      <Route path={paths.login} component={LoginPage} />
      <Route path={paths.register} component={SignUp} />
      <Route path={paths.requestNewPassword} component={RequestNewPassword} />
      <Route path={paths.recoveryPassword} component={RecoveryPassword} />
      <Route path={paths.dashboard} name='dashboard' exact component={Dashboard} isPrivate />
      <Route path={paths.categories} name='categories' exact component={Categories} isPrivate />
      <Route path={paths.services} name='services' exact component={Services} isPrivate />
      <Route path={paths.users} name='users' exact component={Users} isPrivate />
    </Switch>
  </Router>
);
