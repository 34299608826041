import React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Card, Link, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import registerBackground from '~/assets/register-background.png';
import paths from '~/routes/paths';

export function SignUp() {
  const theme = useTheme();
  return (
    <Grid container component='main' sx={{ minHeight: '100vh' }}>
      <Grid item md={8} sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}>
        <Card sx={{ border: '1px solid #dedede' }} elevation={0}>
          <img src={registerBackground} style={{ width: '100%', height: '100vh' }} alt='Good Job' />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          background: theme.palette.common.white,
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ minHeight: '100vh', [theme.breakpoints.down('lg')]: { padding: '10px' } }}
        >
          <Grid item xs={10}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={10}>
                <Typography
                  variant='h6'
                  align='left'
                  sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                  }}
                >
                  Cadastre-se em nossa plataforma
                </Typography>
                <Typography
                  component='div'
                  align='left'
                  sx={{
                    color: '#B7B7B5',
                    mt: 3,
                    mb: 5,
                    fontSize: '12pt',
                  }}
                >
                  Torne-se um cliente ou parceiro{' '}
                  <span style={{ color: theme.palette.primary.main, fontWeight: `bold` }}>Good Job</span> e desfrute de
                  todos os nossos serviços.
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant='subtitle1'
                  align='left'
                  sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3,
                  }}
                >
                  Escolha primeiro o que deseja fazer
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='large'
                  sx={{
                    mt: 1,
                    mb: 3,
                    textTransform: 'none',
                    fontSize: '12pt',
                    ':hover': { background: theme.palette.secondary.main },
                    height: '54px',
                  }}
                >
                  Quero contratar serviços
                </Button>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='large'
                  sx={{
                    mt: 1,
                    mb: 5,
                    textTransform: 'none',
                    fontSize: '12pt',
                    ':hover': { background: theme.palette.secondary.main },
                    height: '54px',
                  }}
                >
                  Quero oferecer meus serviços
                </Button>
              </Grid>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={1}>
                  <Box sx={{ textAlign: 'right' }}>
                    <ArrowBackIosIcon fontSize='small' sx={{ color: '#3134BA', fontSize: 12 }} />
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    component='h2'
                    variant='h6'
                    align='left'
                    sx={{
                      fontSize: '11pt',
                      fontWeight: 500,
                      color: '#3134BA',
                    }}
                  >
                    <Link
                      href={paths.login}
                      underline='none'
                      sx={{
                        marginLeft: '5px',
                        fontSize: '13px',
                        fontWeight: 500,
                      }}
                    >
                      Voltar ao Login
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
