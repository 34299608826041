import React from 'react';

import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent } from '@mui/material';

import api from '@api';

import { Notify } from '@components/Notify';
export function DeleteService({ id, closeModal, loadServices }) {
  const handleDelete = async (id) => {
    try {
      await api.services.delete(id);
      Notify.success({ text: 'Serviço deletado com sucesso!' });
      loadServices();
      closeModal();
    } catch (e) {
      Notify.error({ text: 'Ops, algo deu errado. Verifique e tente novamente!' });
    }
  };
  return (
    <>
      <Dialog open={closeModal}>
        <DialogTitle>{'Deseja mesmo deletar?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Se você deseja mesmo deletar, entenda que ao fazer isso essas informações não poderão ser recuperadas pelo
            sistema, tenha certeza antes de concluir a ação de deletar informações, qualquer dúvida procure o
            responsável pelo sistema.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete(id)} autoFocus>
            Sim
          </Button>
          <Button onClick={() => closeModal()}>Não</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
