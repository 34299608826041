import React from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

/**
 * Componente de seleção de opções
 * @description Usado para facilitar o uso de campo de seleção de opções com validação da lib react-hook-form
 * @author Gabriel Costa <gabriel.costa@creathus.org.br>
 * @param name string - name usado no input para controle do formulário
 * @param label string - titulo do campo no formulário
 * @param control Object - instancia do formulario que está controlando o input
 * @param defaultValue string - opção selecionada por padrão no select
 * @param children ReactElement - opções do select em formato de elemento do react
 * @param helperText string - texto com mesagem de validação
 * @param rules Object - configurações de validação para o input usadas no react-hook-form
 * @param rest Object - restante das propriedades para o component material-ui
 * @returns ReactElement retorna um elemento HTML
 */
export const FormInputSelect = ({
  name,
  label,
  control,
  defaultValue = '',
  children,
  helperText = undefined,
  rules = { required: true },
  ...rest
}) => {
  const onChange = rest['onChange'];
  delete rest.onChange;

  const labelId = `${name}-label`;

  return (
    <FormControl {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules, required: !!rest['required'] }}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <Select
              {...rest}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              value={field.value}
              label={label}
            >
              {children}
            </Select>
          );
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
