import { styled } from '@mui/material';

interface FooterProps {
  isOpenMenu: boolean;
  isMobile: boolean;
}

export const Footer = styled('footer', {
  shouldForwardProp: (prop) => prop !== 'isOpenMenu' && prop !== 'isMobile',
})<FooterProps>(({ isOpenMenu, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isMobile || (!isMobile && !isOpenMenu) ? 'center' : 'flex-end',
  height: isMobile || (!isMobile && !isOpenMenu) ? '60px' : '100%',
  background: isMobile || (!isMobile && !isOpenMenu) ? '#fff' : 'transparent',
  padding: isMobile || (!isMobile && !isOpenMenu) ? '12px 24px' : '0 12px 24px',
  whiteSpace: 'normal',
  boxSizing: 'border-box',
  p: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '11px',
    color: '#bababa',
  },
}));
