import { SxProps, Theme } from '@mui/material';

interface TableProps {
  tableStyle?: string;
  theme?: Theme;
}

export const tableStyles = ({ tableStyle }: TableProps): SxProps => ({
  padding: '2px',
  borderCollapse: 'unset',
  verticalAlign: 'middle',
  background: '#F9FAFC',
  borderSpacing: tableStyle === 'spaced' ? '0 2px' : 0,
  '& td, th': {
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#2F4A7D',
  },
  '& th': {
    color: '#2F4A7D',
  },
});

export const tableBodyStyles = ({ tableStyle, theme }: TableProps): SxProps => ({
  '& tr': {
    borderRadius: tableStyle === 'spaced' ? '4px' : 0,
    boxShadow: tableStyle === 'spaced' ? '0 1px 3px 0 rgb(0 0 0 / 30%)' : '0 1px 1px 0 rgb(0 0 0 / 30%)',
    transition: 'all .15s ease',
    background: '#FFF',
    '&:hover': {
      background: '#0000000A',
    },
  },
  '& td': {
    border: 0,
    padding: '8px 16px',
    color: '#78747E',
  },
  '& .table-button-actions .MuiIconButton-root ': {
    padding: 0.6,
    fontSize: 'initial',
    borderRadius: 3,
    '&:hover': { backgroundColor: theme.palette.primary[400] },
    '& svg': {
      fontSize: 24,
    },
    '&:not(:first-of-type)': {
      marginLeft: 1,
    },
  },
});

export const tableHeaderStyles = ({ theme }: TableProps): SxProps => ({
  background: theme.palette.primary[200],
  boxShadow: '0 1px 0 0 rgb(0 0 0 / 20%)',
  borderRadius: '6px',
  '& .MuiTableSortLabel-root': {
    color: '#78747E',
  },
  '& .Mui-active': {
    color: '#000',
  },
  '& th': {
    fontFamily: 'sans-serif',
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: '8px 16px',
    '&:hover': {
      background: theme.palette.primary[400],
    },
    '&:first-of-type': {
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
    },
  },
});
