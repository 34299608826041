import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import api from '.';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export class ApiError extends Error {}

let refreshPromise: any;

const axios: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const handleRefreshToken = async () => {
  const storagedRefreshToken = localStorage.getItem('@goodjob:refreshToken') || '';
  const { accessToken, refreshToken } = await api.auth.refreshToken(storagedRefreshToken);

  const userData = await api.users.me({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  localStorage.setItem('@goodjob:accessToken', accessToken);
  localStorage.setItem('@goodjob:refreshToken', refreshToken);
  localStorage.setItem('@goodjob:user', JSON.stringify(userData));

  return {
    access_token: accessToken,
    refresh_token: refreshToken,
  };
};

// refresh token
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    const storagedRefreshToken = localStorage.getItem('@goodjob:refreshToken');

    const originalRequest = error.config;
    const is_refresh_request = originalRequest.url === '/api/v1/auth/refresh';
    const has_refresh_token = !!storagedRefreshToken;
    if (error.response.status === 401 && has_refresh_token && !is_refresh_request) {
      if (!originalRequest._retry) {
        try {
          originalRequest._retry = true;
          if (!refreshPromise) {
            refreshPromise = handleRefreshToken();
          }
          const { access_token } = await refreshPromise;
          originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
          return axios(originalRequest);
        } catch (e) {
          // refresh token failed, redirect to login
          localStorage.clear();
          throw e;
        } finally {
          refreshPromise = null;
        }
      } else {
        // Do nothing, 401 without a refresh token only happens in login
      }
    } else if (error.response.status === 403) {
      console.log(error.response);
      throw new ApiError('Sem permissão para acessar esse recurso');
    } else if (
      error.response.status === 400 ||
      error.response.status === 403 ||
      (error.response.status === 500 && error.response.data.message)
    ) {
      // TODO check for other types of  API 400 response structures
      throw new ApiError(error.response.data.message);
    }
    throw error;
  },
);

// Add a request interceptor
axios.interceptors.request.use(async (config: AxiosRequestConfig<any>) => {
  const accessToken = localStorage.getItem('@goodjob:accessToken');
  if (process.env.NODE_ENV === 'development') {
    await sleep(500);
  }
  if (accessToken && config.headers && !config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

export default axios;
