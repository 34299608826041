import styled from 'styled-components';

export const StrengthIndicator = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 3px 14px 0;

  span {
    font-weight: bold;
    margin-left: 5px;
  }
`;
