import React from 'react';

export function UploadIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='127' height='127' viewBox='0 0 127 127'>
      <defs>
        <clipPath id='clip-path'>
          <path
            fill='#fff'
            stroke='rgba(0,0,0,0)'
            strokeWidth='1'
            d='M6646.411 2255.606h0-27.435c-.459 0-.893 0-1.353-.027a22.98 22.98 0 011.353-45.919h.351a35.146 35.146 0 0167.92 4.028 21.628 21.628 0 01-4.758 41.756 21.963 21.963 0 01-2.7.163h-24.211v-14.843l3.355 4.026a4.582 4.582 0 107.039-5.867l-11.458-13.748a4.579 4.579 0 00-7.039 0l-11.458 13.747a4.583 4.583 0 107.041 5.867l3.353-4.026v14.843z'
            data-name='Subtração 1'
          ></path>
        </clipPath>
      </defs>
      <g data-name='Grupo 3764' transform='translate(-1660 -231)'>
        <g clipPath='url(#clip-path)' data-name='Grupo de máscara 15' transform='translate(-4925.203 -1926.606)'>
          <g data-name='Grupo 3763' transform='translate(6594.735 2180.518)'>
            <path
              fill='#98dbf9'
              d='M103.244 104.087H4.693A4.692 4.692 0 010 99.394v-65.7A4.692 4.692 0 014.693 29h98.551a4.692 4.692 0 014.693 4.693v65.7a4.692 4.692 0 01-4.693 4.693'
              data-name='Caminho 7335'
              transform='translate(0 -29)'
            ></path>
            <path
              fill='#f08b22'
              d='M20.718 40.859A7.859 7.859 0 1112.859 33a7.859 7.859 0 017.859 7.859'
              data-name='Caminho 7336'
              transform='translate(27.014 -12.925)'
            ></path>
            <path
              fill='#464853'
              d='M21.335 47.51L0 68.549v5.432a4.313 4.313 0 004.311 4.311h50.447L27.823 47.51a4.31 4.31 0 00-6.489 0'
              data-name='Caminho 7337'
              transform='translate(4.635 .455)'
            ></path>
            <path
              fill='#464853'
              d='M85.983 78.845v-7.334l-28.42-28.42a4.31 4.31 0 00-6.1 0L11.4 83.156h70.27a4.31 4.31 0 004.311-4.311'
              data-name='Caminho 7338'
              transform='translate(17.812 -4.408)'
            ></path>
            <path
              fill='#28292f'
              d='M54.1 68.724V61.39l-9.912-9.912a3.049 3.049 0 00-4.308 4.312l2.526 2.526a20.811 20.811 0 016.1 14.719h1.289a4.31 4.31 0 004.305-4.311'
              data-name='Caminho 7339'
              transform='translate(49.692 5.713)'
            ></path>
            <path
              fill='#28292f'
              d='M28.451 55.922a7.83 7.83 0 00-9.438-1.806l12.552 14.345h7.859z'
              data-name='Caminho 7340'
              transform='translate(29.087 6.626)'
            ></path>
            <path
              fill='#5a5d6b'
              d='M27.034 54.776a2.149 2.149 0 003.048 0l6.1-6.094a2.158 2.158 0 00-3.05-3.052l-6.1 6.1a2.151 2.151 0 000 3.046'
              data-name='Caminho 7341'
              transform='translate(35.147 -.743)'
            ></path>
            <path
              fill='#5a5d6b'
              d='M7.2 58.948a2.153 2.153 0 003.048 0l6.1-6.1A2.157 2.157 0 1013.3 49.8l-6.1 6.1a2.157 2.157 0 000 3.048'
              data-name='Caminho 7342'
              transform='translate(12.232 4.078)'
            ></path>
            <path
              fill='#fff'
              d='M28.806 36a4.9 4.9 0 00-4.436 2.835 3.923 3.923 0 10-2.44 6.988h6.876a4.912 4.912 0 100-9.823'
              data-name='Caminho 7343'
              transform='translate(39.555 -10.031)'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
