import React from 'react';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from '@mui/system';

import { RoutePages } from './routes';
import { AppContextProvider } from './store';
import { defaultTheme } from './styles/default-theme';
import GlobalStyle from './styles/global-styles';

import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <AppContextProvider>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <RoutePages />
      </ThemeProvider>
      <ToastContainer />
    </AppContextProvider>
  );
}

export default App;
