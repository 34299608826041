import axios from './api';

type RequestWithPagination = { order_by: string; order: string; page: number; per_page: number };

export default {
  async me(config: any) {
    const res = await axios.get('/api/v1/users/me', config);
    return res.data;
  },
  async all({ order_by, order, page, per_page }: RequestWithPagination) {
    const res = await axios.get(`/api/v1/users`, {
      params: {
        order_by,
        order,
        page,
        per_page,
      },
    });
    return res.data;
  },
  async storeUser(data) {
    try {
      const res = await axios.post('/api/v1/users', data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      return res.data;
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw new Error('Sem permissão para esse recurso');
      } else if (err.response && err.response.status === 404) {
        throw new Error('Serviço não esta mais disponivel');
      } else {
        throw new Error('Não foi possivel criar seu Serviço, tente novamente mais tarde');
      }
    }
  },
  async delete(id) {
    try {
      const res = await axios.delete(`/api/v1/users/${id}`);
      return res.data;
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw new Error('Sem permissão para esse recurso');
      } else if (err.response && err.response.status === 404) {
        throw new Error('Categoria não esta mais disponivel');
      } else {
        throw new Error('Não foi possivel criar sua Categoria, tente novamente mais tarde');
      }
    }
  },
};
