import { useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import paths from '~/routes/paths';

const DRAWER_TYPE_SIZE = {
  normal: 276,
  mini: 80,
};

export default function useMenu() {
  const theme = useTheme();
  const matchesWidthBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerWidth, setDrawerWidth] = useState(DRAWER_TYPE_SIZE.normal);
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [isMobile, setIsMobile] = useState(matchesWidthBreakpoint);
  const [selectedMenuOption, setSelectedMenuOption] = useState(paths.dashboard);

  useEffect(() => {
    const statusMenu = JSON.parse(localStorage.getItem('@goodjob:openedMenu'));

    if (statusMenu !== null) {
      handleToggleMenu(statusMenu);
    }
  }, []);

  const handleToggleMenu = (status) => {
    localStorage.setItem('@goodjob:openedMenu', status);
    setDrawerWidth(status ? DRAWER_TYPE_SIZE.normal : DRAWER_TYPE_SIZE.mini);

    setIsOpenMenu(status);
  };

  const handleSelectMenuOption = (option: string) => {
    setSelectedMenuOption(option);
  };

  return { drawerWidth, isOpenMenu, isMobile, selectedMenuOption, handleToggleMenu, handleSelectMenuOption };
}
