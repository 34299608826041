import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Box,
  useTheme,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  FormHelperText,
  MenuItem,
} from '@mui/material';

import api from '@api';

import { FormInputSelect } from '@components/FormInputSelect';
import { FormInputText } from '@components/FormInputText';
import { Notify } from '@components/Notify';
import { Switch } from '@components/Switch';
import { UploadImage } from '@components/UploadImage';

const DEFAULT_ERROR_MESSAGE = 'Campo obrigatório não preenchido.';

const schema = Yup.object()
  .shape({
    name: Yup.string().required(DEFAULT_ERROR_MESSAGE),
    email: Yup.string().email().required(DEFAULT_ERROR_MESSAGE),
    cellphone: Yup.number().positive().integer().required(DEFAULT_ERROR_MESSAGE),
    user_type: Yup.string().required(DEFAULT_ERROR_MESSAGE),
  })
  .required();

export const UserTypes = [
  { id: 1, name: 'ADMIN', text: 'Administrador' },
  { id: 2, name: 'CLIENT', text: 'Cliente' },
  { id: 3, name: 'COLABORATOR', text: 'Colaborador' },
  { id: 4, name: 'SERVICE_PROVIDER', text: 'Prestador de Serviço' },
];
export function StoreUser({ user, closeModal, loadUsers }) {
  const theme = useTheme();
  const [status, setStatus] = useState(true);
  const [fileError, setFileError] = useState(false);
  const [file, setFile] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setFileError(false);
  }, [file]);

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        pictureUrl: user.pictureUrl,
        email: user.email,
        isEmailConfirmed: user.isEmailConfirmed,
        cellphone: user.cellphoneNumber,
        user_type: user.user_type,
      });
      setStatus(user.isActive);
    }
  }, [user]);

  const sendForm = async (dataForm: any) => {
    if (!file && !user) {
      setFileError(true);
      return;
    }
    try {
      const formData = new FormData();

      if (user) formData.append('id', user.id);

      Object.keys(dataForm).forEach((key) => {
        formData.append(key, dataForm[key]);
      });
      formData.append('status', status.toString());

      if (file) formData.append('image', file);

      await api.users.storeUser(formData);
      Notify.success({ text: 'Usuário foi criada!' });
      loadUsers();
      closeModal();
      console.log(formData);
    } catch (err) {
      Notify.error({ text: 'Não foi possível criar seu usuário. Tente novamente!' });
    }
  };

  return (
    <>
      <DialogContent>
        <Box
          component='form'
          noValidate
          onSubmit={handleSubmit(sendForm)}
          sx={{ '& .MuiFormControl-root': { marginY: 1.5 } }}
        >
          <FormInputText
            control={control}
            required
            fullWidth
            label='Nome'
            name='name'
            size='medium'
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
          />
          <FormInputText
            control={control}
            required
            fullWidth
            label='Email'
            name='email'
            size='medium'
            error={!!errors.email}
            helperText={!!errors.email && errors.name.message}
          />
          <FormInputText
            control={control}
            required
            fullWidth
            label='Telefone'
            name='cellphone'
            size='medium'
            error={!!errors.cellphone}
            helperText={!!errors.cellphone && errors.cellphone.message}
          />
          <FormInputSelect
            control={control}
            required
            fullWidth
            label='Permissão'
            name='user_type'
            size='medium'
            error={!!errors.user_type}
            helperText={!!errors.user_type && errors.user_type.message}
          >
            <MenuItem>Selecione uma categoria</MenuItem>
            {UserTypes.length &&
              UserTypes.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.text}
                </MenuItem>
              ))}
          </FormInputSelect>
          <UploadImage title='Adicionar foto do Usuário' file={file} setFile={setFile} />
          {fileError && (
            <FormHelperText sx={{ marginLeft: 2, color: '#d32f2f' }}>
              É obrigatório o upload de uma imagem
            </FormHelperText>
          )}

          <FormControl sx={{ padding: 1 }}>
            <FormLabel sx={{ mb: 1, fontSize: '1rem' }}>Status</FormLabel>
            <Switch onChange={() => setStatus(!status)} checked={status} />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleSubmit(sendForm)} color='success' variant='contained'>
          Salvar
        </Button>
      </DialogActions>
    </>
  );
}
