import React, { createContext } from 'react';

import useAuth from './hooks/useAuth';

interface User {
  id: string;
  name: string;
  email: string;
}

interface AuthLoginForm {
  email: string;
  password: string;
  remember_me: boolean;
}

interface AuthContextData {
  authenticated: boolean;
  user: User;
  isLoading: boolean;
  handleEmailLogin({ email, password, remember_me }: AuthLoginForm): Promise<void>;
  handleLogout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, handleEmailLogin, handleLogout, isLoading } = useAuth();

  return (
    <AuthContext.Provider value={{ authenticated: !!user, user, isLoading, handleEmailLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
