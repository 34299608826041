import React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Card, Link, Typography, useTheme, Grid, Box } from '@mui/material';

import requestNewPasswordBackground from '~/assets/request-new-password-background.png';
import paths from '~/routes/paths';

import FormRequestNewPassword from './components/FormRequestNewPassword';

export function RequestNewPassword() {
  const theme = useTheme();

  return (
    <Grid container component='main' sx={{ minHeight: '100vh' }}>
      <Grid item md={8} sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}>
        <Card sx={{ border: '1px solid #dedede' }} elevation={0}>
          <img src={requestNewPasswordBackground} style={{ width: '100%', height: '100vh' }} alt='Good Job' />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          background: theme.palette.common.white,
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ minHeight: '100vh', [theme.breakpoints.down('lg')]: { padding: '10px' } }}
        >
          <Grid item xs={10}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={10}>
                <Typography
                  variant='h6'
                  align='left'
                  sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                  }}
                >
                  Esqueceu sua senha?
                </Typography>
                <Typography
                  component='div'
                  align='left'
                  sx={{
                    color: '#B7B7B5',
                    marginTop: '20px',
                    marginBottom: '30px',
                    fontSize: '12pt',
                  }}
                >
                  Digite seu e-mail e enviaremos instruções para redefinir sua senha
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <FormRequestNewPassword />
              </Grid>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={1}>
                  <Box sx={{ textAlign: 'right' }}>
                    <ArrowBackIosIcon fontSize='small' sx={{ color: '#3134BA', fontSize: 12 }} />
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    component='h2'
                    variant='h6'
                    align='left'
                    sx={{
                      fontSize: '11pt',
                      fontWeight: 500,
                      color: '#3134BA',
                    }}
                  >
                    <Link
                      href={paths.login}
                      underline='none'
                      sx={{
                        marginLeft: '5px',
                        fontSize: '13px',
                        fontWeight: 500,
                      }}
                    >
                      Voltar ao Login
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
