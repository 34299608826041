import axios from './api';

export default {
  async login(data: { email: string; password: string; remember_me: boolean }) {
    try {
      const res = await axios.post('/api/v1/auth/login', data);
      return res.data;
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        throw new Error('Email ou senha incorretos');
      } else {
        throw new Error('Ocorreu um erro ao realizar o login');
      }
    }
  },
  async refreshToken(refresh_token: string) {
    const res = await axios.post('/api/v1/auth/refresh', { refresh_token });
    return res.data;
  },
};
