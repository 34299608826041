const STRENGTH_TYPES = {
  poor: 'Muito Fraca',
  weak: 'Fraca',
  normal: 'Média',
  good: 'Boa',
  strong: 'Forte',
};

const STRENGTH_COLORS = {
  poor: '#D32F2F',
  weak: '#ED6C02',
  normal: '#EDE502',
  good: '#0288D1',
  strong: '#2E7D32',
};

const hasNumber = (password) => new RegExp(/[0-9]/).test(password);

const hasMixed = (password) => new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password);

const hasSpecial = (password) => new RegExp(/[!#@$%^&*)(+=._-]/).test(password);

const verifyStrength = (strengths) => {
  if (!strengths) {
    return { label: '', color: '' };
  }

  if (strengths <= 2) {
    return { label: STRENGTH_TYPES['poor'], color: STRENGTH_COLORS['poor'] };
  } else if (strengths <= 3) {
    return { label: STRENGTH_TYPES['weak'], color: STRENGTH_COLORS['weak'] };
  } else if (strengths <= 4) {
    return { label: STRENGTH_TYPES['normal'], color: STRENGTH_COLORS['normal'] };
  } else if (strengths <= 5) {
    return { label: STRENGTH_TYPES['good'], color: STRENGTH_COLORS['good'] };
  } else {
    return { label: STRENGTH_TYPES['strong'], color: STRENGTH_COLORS['strong'] };
  }
};

export const PasswordStrength = (password) => {
  let strengths = 0;
  if (password.length >= 5) strengths += 1;
  if (password.length >= 7) strengths += 1;
  if (hasNumber(password)) strengths += 1;
  if (hasMixed(password)) strengths += 2;
  if (hasSpecial(password)) strengths += 2;
  return verifyStrength(strengths);
};
