import React from 'react';

import { Link, Grid, Typography, useTheme, Card } from '@mui/material';

import loginBackground from '~/assets/login-background.png';
import paths from '~/routes/paths';

import FormEmailLogin from './components/FormEmailLogin';

export function LoginPage() {
  const theme = useTheme();

  return (
    <Grid container component='main' sx={{ minHeight: '100vh' }}>
      <Grid item md={8} sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}>
        <Card sx={{ border: '1px solid #dedede' }} elevation={0}>
          <img src={loginBackground} style={{ width: '100%', height: '100vh' }} alt='Good Job' />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          background: theme.palette.common.white,
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ minHeight: '100vh', [theme.breakpoints.down('lg')]: { padding: '10px' } }}
        >
          <Grid item xs={10}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={10}>
                <Typography
                  variant='h6'
                  align='left'
                  sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                  }}
                >
                  Bem-vindo ao Good Job!
                </Typography>
                <Typography
                  component='div'
                  align='left'
                  sx={{
                    color: '#B7B7B5',
                    marginTop: '20px',
                    marginBottom: '30px',
                    fontSize: '12pt',
                  }}
                >
                  Faça login na sua conta e comece a sua independência financeira
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <FormEmailLogin />
              </Grid>
              <Typography
                component='h2'
                variant='h6'
                align='left'
                sx={{
                  fontSize: '11pt',
                  fontWeight: 500,
                  color: '#6F6F6C',
                }}
              >
                Novo em nossa plataforma?
                <Link
                  href={paths.register}
                  underline='always'
                  sx={{
                    marginLeft: '5px',
                    fontSize: '13px',
                    fontWeight: 500,
                  }}
                >
                  Crie a sua conta aqui
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
