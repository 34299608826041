import { FC } from 'react';

import { DialogProps } from '@mui/material/Dialog/Dialog';
import { Breakpoint } from '@mui/system';

import { DialogTitle } from './DialogTitle';
import { StyledDialog } from './styles';

interface Props {
  title: string;
  maxWidth?: Breakpoint;
  open: boolean;
  handleClose: any;
}

export const Modal: FC<Props & DialogProps> = ({ children, title, maxWidth = 'md', handleClose, ...rest }) => {
  return (
    <StyledDialog maxWidth={maxWidth} onClose={handleClose} aria-labelledby='customized-dialog-title' {...rest}>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      {children}
    </StyledDialog>
  );
};
