import React, { useContext } from 'react';

import { Toolbar } from '@mui/material';

import logo from '@assets/logo_2.png';
import logoMini from '@assets/logo_mini.svg';

import { MenuContext } from '~/store/contexts/MenuContext';

export const MenuToolbar: React.FC = () => {
  const { isOpenMenu } = useContext(MenuContext);

  return (
    <Toolbar
      sx={{
        background: 'transparent',
        justifyContent: isOpenMenu ? 'start' : 'center',
        marginY: '10px',
      }}
    >
      <img alt='logo' src={isOpenMenu ? logo : logoMini} height={isOpenMenu ? 55 : 36} />
    </Toolbar>
  );
};
