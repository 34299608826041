import React, { useEffect, useState } from 'react';

import { Grid, useTheme } from '@mui/material';

export function Dashboard() {
  const theme = useTheme();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        Dashboard
      </Grid>
    </Grid>
  );
}
