import React, { useEffect, useState } from 'react';

import { useStateIfMounted } from 'use-state-if-mounted';

import { Grid, Container, useTheme, Button } from '@mui/material';

import api from '@api';

import { StoreCategory } from '@pages/Categories/StoreCategory';

import { Modal } from '@components/Modal';
import { Notify } from '@components/Notify';
import { StatusIndicator } from '@components/StatusIndicator';

import { DataGrid } from '~/components/DataGrid';

import { DeleteCategory } from './DeleteCategory';

interface CategoriesRequest {
  per_page?: number;
  total_items?: number;
  total_pages?: number;
  current_page?: number;
  data: CategoriesData[];
}

interface CategoriesData {
  id: number;
  name: string;
  description: string;
  image: string;
  is_active: boolean;
}
type Order = 'asc' | 'desc';

export function Categories() {
  const theme = useTheme();

  const [categories, setCategories] = useStateIfMounted<CategoriesData[]>([]);
  const [id, setId] = useState<number>(null);
  const [page, setPage] = useState(0);
  const [per_page, setPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [order_by, setOrderBy] = useState('name');
  const [totalItems, setTotalItems] = useStateIfMounted(0);
  const [category, setCategory] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [action, setAction] = useState('');

  const handleToggleModal = () => {
    setCategory(null);
    setIsOpenModal(!isOpenModal);
  };

  const handleDeleteCategory = (id) => {
    setId(id);
    setAction('delete');
    setModalTitle('Deseja deletar mesmo?');
    setIsOpenModal(!isOpenModal);
  };

  const handleEditCategory = (category) => {
    setAction('save');
    setModalTitle('Editar Categoria');
    setCategory(category);
    setIsOpenModal(!isOpenModal);
  };

  const handleNewCategory = () => {
    setAction('save');
    setModalTitle('Adicionar Nova Categoria');
    handleToggleModal();
  };

  const loadCategories = async () => {
    try {
      const request: CategoriesRequest = await api.categories.loadCategories({
        page: page + 1,
        per_page,
        order: order,
        order_by,
      });
      setCategories(request.data);
      setTotalItems(request?.total_items || 0);
    } catch (err) {
      Notify.error({ text: err.message });
    }
  };

  useEffect(() => {
    loadCategories();
  }, [page, per_page, order, order_by]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Container maxWidth={false}>
          <Button
            onClick={handleNewCategory}
            fullWidth
            variant='contained'
            color='primary'
            size='small'
            sx={{
              mt: 1,
              mb: 2,
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 500,
              ':hover': { background: theme.palette.secondary.main },
              height: '40px',
              width: '120px',
            }}
          >
            Adicionar
          </Button>
        </Container>
        <Container>
          <Grid item xs={12} paddingTop={3}>
            <DataGrid
              columns={[
                {
                  name: 'name',
                  header: 'NOME DA CATEGORIA',
                },
                {
                  name: 'image',
                  header: 'IMAGEM',
                  custom: (row: CategoriesData) => <img src={row.image} width={38} alt={row.image} />,
                },
                {
                  name: 'is_active',
                  header: 'STATUS',
                  align: 'center',
                  custom: (row: CategoriesData) => <StatusIndicator status={row.is_active} />,
                },
              ]}
              defaultHandleActions={{
                edit: (row: CategoriesData) => handleEditCategory(row),
                delete: (row: CategoriesData) => handleDeleteCategory(row.id),
              }}
              templateActions={['edit', 'delete']}
              tableStyle='spaced'
              data={categories}
              page={page}
              pageSize={per_page}
              total={totalItems}
              order={order}
              orderBy={order_by}
              changePage={setPage}
              changePageSize={setPerPage}
              changeOrder={setOrder}
              changeOrderBy={setOrderBy}
            />
            <Modal title={modalTitle} open={isOpenModal} handleClose={handleToggleModal}>
              {action === 'save' && (
                <StoreCategory category={category} closeModal={handleToggleModal} loadCategories={loadCategories} />
              )}
              {action === 'delete' && (
                <DeleteCategory id={id} closeModal={handleToggleModal} loadCategories={loadCategories} />
              )}
            </Modal>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
