import React, { ComponentProps } from 'react';

export const combineContexts = (...components: any[]): React.FC => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      /* eslint-disable react/display-name */
      return ({ children }: ComponentProps<React.FC<{ children: React.ReactNode }>>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};
