import React, { useContext } from 'react';

import { Box, Container, Toolbar } from '@mui/material';

import { MenuContext } from '~/store/contexts/MenuContext';

import { Footer } from './Footer';
import { Header } from './Header';
import { Menu } from './Menu';

const DefaultLayout = ({ children }) => {
  const { isOpenMenu, isMobile } = useContext(MenuContext);

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Menu />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth='xl' sx={{ mt: 2, mb: 5, minHeight: '100%' }}>
          {children}
        </Container>
        {(!isOpenMenu || isMobile) && <Footer />}
      </Box>
    </Box>
  );
};

export default DefaultLayout;
