import React, { ReactElement, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';

interface Props {
  icon: ReactElement;
  text: string;
  path?: string;
}

import { MenuContext } from '~/store/contexts/MenuContext';

export const MenuItem: React.FC<Props> = ({ icon, text, path }) => {
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    handleSelectMenuOption(history.location.pathname);
  }, []);

  const { handleSelectMenuOption, selectedMenuOption, isOpenMenu } = useContext(MenuContext);

  const handleNavigate = (path) => {
    if (path) {
      handleSelectMenuOption(path);
      history.push(path);
    }
  };

  return (
    <ListItem
      button
      onClick={() => handleNavigate(path)}
      sx={{
        ...(selectedMenuOption === path && {
          background: theme.palette.common.white,
        }),
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      {isOpenMenu ? <ListItemText primary={text} /> : ''}
    </ListItem>
  );
};
