import React, { createContext } from 'react';

import useLoading from './hooks/useLoading';

interface Loading {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

const LoadingContext = createContext<Loading>({} as Loading);

const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading, showLoading, hideLoading } = useLoading();

  return <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>{children}</LoadingContext.Provider>;
};

export { LoadingContext, LoadingProvider };
