export default {
  login: '/login',
  register: '/register',
  requestNewPassword: '/request-new-password',
  recoveryPassword: '/recovery-password',
  dashboard: '/dashboard',
  categories: '/categories',
  services: '/services',
  users: '/users',
};
