import React, { useEffect, useState } from 'react';

import { useStateIfMounted } from 'use-state-if-mounted';

import { Grid, Container, useTheme, Button } from '@mui/material';

import api from '@api';

import { Modal } from '@components/Modal';
import { Notify } from '@components/Notify';
import { StatusIndicator } from '@components/StatusIndicator';

import { DataGrid } from '~/components/DataGrid';

import { DeleteService } from './DeleteService';
import { StoreService } from './StoreService';

interface ServicesRequest {
  per_page?: number;
  total_items?: number;
  total_pages?: number;
  current_page?: number;
  data: ServicesData[];
}

interface ServicesData {
  id: number;
  name: string;
  description: string;
  image: string;
  is_active: boolean;
  category: {
    id: number;
    name: string;
  };
}

type Order = 'asc' | 'desc';

export function Services() {
  const theme = useTheme();

  const [services, setServices] = useStateIfMounted<ServicesData[]>([]);
  const [page, setPage] = useState(0);
  const [id, setId] = useState<number>(null);
  const [per_page, setPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [order_by, setOrderBy] = useState('name');
  const [totalItems, setTotalItems] = useStateIfMounted(0);
  const [service, setService] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [action, setAction] = useState('');

  const handleToggleModal = () => {
    setService(null);
    setIsOpenModal(!isOpenModal);
  };

  const handleDeleteService = (id) => {
    setId(id);
    setAction('delete');
    setModalTitle('Deseja deletar mesmo?');
    setIsOpenModal(!isOpenModal);
  };

  const handleEditService = (service) => {
    setAction('save');
    setModalTitle('Editar Serviço');
    setService(service);
    setIsOpenModal(!isOpenModal);
  };

  const handleNewService = () => {
    setAction('save');
    setModalTitle('Adicionar Novo Serviço');
    handleToggleModal();
  };

  const loadServices = async () => {
    try {
      const request: ServicesRequest = await api.services.all({
        page: page + 1,
        per_page,
        order: order,
        order_by,
      });
      setServices(request.data);
      setTotalItems(request?.total_items || 0);
    } catch (err) {
      Notify.error({ text: err.message });
    }
  };

  useEffect(() => {
    loadServices();
  }, [page, per_page, order, order_by]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Container maxWidth={false}>
          <Button
            onClick={handleNewService}
            fullWidth
            variant='contained'
            color='primary'
            size='small'
            sx={{
              mt: 1,
              mb: 2,
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 500,
              ':hover': { background: theme.palette.secondary.main },
              height: '40px',
              width: '120px',
            }}
          >
            Adicionar
          </Button>
        </Container>
        <Container>
          <Grid item xs={12} paddingTop={3}>
            <DataGrid
              columns={[
                {
                  name: 'name',
                  header: 'NOME DO SERVIÇO',
                },
                {
                  name: 'category',
                  header: 'CATEGORIA',
                  custom: (row: ServicesData) => row.category.name,
                },
                {
                  name: 'image',
                  header: 'IMAGEM',
                  custom: (row: ServicesData) => (
                    <img src={process.env.REACT_APP_API_UPLOAD_URL + row.image} width={38} alt={row.image} />
                  ),
                },
                {
                  name: 'is_active',
                  header: 'STATUS',
                  align: 'center',
                  custom: (row: ServicesData) => <StatusIndicator status={row.is_active} />,
                },
              ]}
              defaultHandleActions={{
                view: (row) => alert('view'),
                edit: (row: ServicesData) => handleEditService(row),
                delete: (row: ServicesData) => handleDeleteService(row.id),
              }}
              templateActions={['edit', 'delete']}
              tableStyle='spaced'
              data={services}
              page={page}
              pageSize={per_page}
              total={totalItems}
              order={order}
              orderBy={order_by}
              changePage={setPage}
              changePageSize={setPerPage}
              changeOrder={setOrder}
              changeOrderBy={setOrderBy}
            />
          </Grid>
          <Modal title={modalTitle} open={isOpenModal} handleClose={handleToggleModal}>
            {action === 'save' && (
              <StoreService service={service} closeModal={handleToggleModal} loadServices={loadServices} />
            )}
            {action === 'delete' && (
              <DeleteService id={id} closeModal={handleToggleModal} loadServices={loadServices} />
            )}
          </Modal>
        </Container>
      </Grid>
    </Grid>
  );
}
