import CloseIcon from '@mui/icons-material/Close';
import { useTheme, Typography, Box, IconButton } from '@mui/material';

export const SelectedFile = ({ file, removeFile }) => {
  const theme = useTheme();

  console.log(file);

  return (
    <Box
      sx={{
        border: '1px solid #E7E6E8',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '68px',
        paddingLeft: 2,
        paddingRight: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          component='img'
          src={URL.createObjectURL(file)}
          width={40}
          height={40}
          sx={{
            marginRight: 1.6,
            padding: 0.5,
            border: '1px solid #E7E6E8',
            borderRadius: '6px',
          }}
        />
        <Box>
          <Typography
            component='p'
            sx={{
              maxWidth: '276px',
              fontSize: 16,
              fontWeight: 400,
              color: '#534F5A',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {file.path}
          </Typography>
          <Typography
            component='p'
            sx={{
              maxWidth: '276px',
              fontSize: 16,
              fontWeight: 400,
              color: '#96939A',
            }}
          >
            270 KB
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={() => removeFile()}>
        <CloseIcon sx={{ color: '#919097' }} />
      </IconButton>
    </Box>
  );
};
