import { useDropzone } from 'react-dropzone';

import { Grid, Typography, Box } from '@mui/material';

import { SelectedFile } from './SelectedFile';

import { UploadIcon } from '@components/UploadIcon';

export const UploadImage = ({ title, file, setFile }) => {
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Typography
      key={file.name}
      align='center'
      component='h6'
      variant='h6'
      sx={{
        color: '#f10909',
      }}
    >
      {errors.map((e) => (
        <Typography key={e.code}>tipo de arquivo é invalido</Typography>
      ))}
    </Typography>
  ));

  return (
    <Grid
      container
      padding={2}
      direction='column'
      sx={{
        marginY: 1,
        borderRadius: '6px',
        border: '1px solid #E7E6E8',
      }}
    >
      <Typography
        align='center'
        component='h2'
        variant='h5'
        sx={{
          marginTop: 0.5,
          marginBottom: 2,
          fontSize: 20,
          fontWeight: 600,
          color: '#524E5A',
        }}
      >
        {title}
      </Typography>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          padding: 1.5,
          borderRadius: '6px',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='rgba(219, 219, 221, 1)' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
      >
        <input {...getInputProps()} />
        <Grid container justifyContent='center'>
          <Grid container justifyContent='center'>
            <UploadIcon />
          </Grid>
          <Grid container maxWidth={290}>
            <Grid item>
              <Typography
                align='center'
                component='h2'
                variant='h5'
                sx={{
                  fontSize: 22,
                  fontWeight: 500,
                  color: '#1D1F23',
                }}
              >
                Solte os arquivos aqui ou clique para fazer upload.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align='center'
                component='p'
                sx={{
                  maxWidth: '276px',
                  marginY: 1,
                  fontSize: 16,
                  fontWeight: 300,
                  color: '#79767E',
                }}
              >
                Permitido *.jpeg, *.jpg, *.png, * 1 arquivo com tamanho máximo de 2 MB
              </Typography>
            </Grid>
            <Grid item>
              <Box>{fileRejectionItems}</Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {file && (
        <Grid item mt={2}>
          <SelectedFile file={file} removeFile={() => setFile(null)} />
        </Grid>
      )}
    </Grid>
  );
};
