import { FC, ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle as MUIDialogTitle, IconButton, useTheme } from '@mui/material';

import { IconButtonStyles } from './styles';

interface Props {
  onClose: () => void;
  children: ReactNode;
}

export const DialogTitle: FC<Props> = ({ children, onClose, ...rest }) => {
  const theme = useTheme();
  return (
    <MUIDialogTitle sx={{ m: 0, p: 2 }} {...rest}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' onClick={onClose} sx={IconButtonStyles(theme)}>
          <CloseIcon sx={{ fontSize: 30, color: theme.palette.primary[200] }} />
        </IconButton>
      ) : null}
    </MUIDialogTitle>
  );
};
