import account from './account';
import auth from './auth';
import categories from './categories';
import services from './services';
import users from './users';

export default {
  auth,
  users,
  account,
  categories,
  services,
};
