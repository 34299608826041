import React, { FC, MouseEvent } from 'react';

import { Box, TableHead as MuiTableHead, TableSortLabel, TableCell, TableRow, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { Column, Order } from './index';
import { tableHeaderStyles } from './styles';

interface Props {
  actions?: boolean;
  actionHeader?: string | false;
  order: Order;
  orderBy: string;
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  columns: Array<Column>;
}

export const TableHead: FC<Props> = ({ order, orderBy, onRequestSort, columns, actionHeader, actions }) => {
  const theme = useTheme();

  const createSortHandler = (property: string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow sx={tableHeaderStyles({ theme })}>
        {columns.map((column) => (
          <TableCell
            key={column.name}
            align={column.align || 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.name && order ? order : false}
          >
            {column.sortable === false ? (
              column.header
            ) : (
              <TableSortLabel
                active={orderBy === column.name}
                direction={orderBy === column.name && order ? order : 'asc'}
                onClick={createSortHandler(column.name)}
              >
                {column.header}
                {orderBy === column.name ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {actions !== false && (
          <TableCell padding='normal' align='center'>
            {actionHeader === undefined ? 'AÇÕES' : !actionHeader ? '' : actionHeader}
          </TableCell>
        )}
      </TableRow>
    </MuiTableHead>
  );
};
