import React from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';

import { PasswordStrength } from '@utils/PasswordStrength';

import { StrengthIndicator } from './styles';

/**
 * Componente de input de texto
 * @description Usado para facilitar o uso de input com validação da lib react-hook-form
 * @author Gabriel Costa <gabriel.costa@creathus.org.br>
 * @param name string - name usado no input para controle do formulário
 * @param control Object - instancia do formulario que está controlando o input
 * @param defaultValue string - valor inicial do input
 * @param rules Object - configurações de validação para o input usadas no react-hook-form
 * @param strengthIndicator boolean - indica se o campo terá a exibição de força de senha
 * @param rest Object - restante das propriedades para o input do material-ui
 * @returns ReactElement retorna um elemento HTML
 */
export const FormInputText = ({
  name,
  control,
  defaultValue = '',
  rules = { required: true },
  strengthIndicator = false,
  ...rest
}) => {
  const onChange = rest['onChange'];
  delete rest.onChange;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, required: !!rest['required'] }}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <>
            <TextField
              {...rest}
              ref={field.ref}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              value={field.value}
            />
            {strengthIndicator && rest['type'] === 'password' && PasswordStrength(field.value).label && (
              <StrengthIndicator>
                Força da senha:
                <span style={{ color: PasswordStrength(field.value).color }}>
                  {PasswordStrength(field.value).label}
                </span>
              </StrengthIndicator>
            )}
          </>
        );
      }}
    />
  );
};
