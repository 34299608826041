import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, useTheme } from '@mui/material';

import { FormInputText } from '@components/FormInputText';
import { Notify } from '@components/Notify';

import api from '~/api';
import paths from '~/routes/paths';
import { LoadingContext } from '~/store/contexts/LoadingContext';

const DEFAULT_ERROR_MESSAGE = 'Campo obrigatório não preenchido.';

const schema = Yup.object()
  .shape({
    password: Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres.').required(DEFAULT_ERROR_MESSAGE),
    passwordConfirmation: Yup.string()
      .required(DEFAULT_ERROR_MESSAGE)
      .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
  })
  .required();

export default function FormRecoveryPassword() {
  const theme = useTheme();
  const history = useHistory();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const [token, setToken] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const urlToken = new URLSearchParams(location.search).get('token');
    if (urlToken) {
      setToken(urlToken);
    }
  });

  const sendFormRecoveryPassword = async (dataForm: any) => {
    if (!token) {
      history.push(paths.login);
      return;
    }
    try {
      showLoading();
      const { password, passwordConfirmation } = dataForm;

      await api.account.recoveryPassword({
        password: password,
        token: token,
        password_confirmation: passwordConfirmation,
      });

      Notify.success({ text: 'Senha alterada com sucesso!' });
      setTimeout(() => {
        history.push(paths.login);
        return;
      }, 1000);
    } catch (err) {
      Notify.error({ text: 'Ops, ocorrou um erro ao alterar a senha.' });
    } finally {
      hideLoading();
    }
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(sendFormRecoveryPassword)} sx={{ mt: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <FormInputText
            control={control}
            margin='normal'
            required
            fullWidth
            name='password'
            label='Nova senha'
            type='password'
            autoComplete='current-password'
            size='medium'
            error={!!errors.password}
            helperText={!!errors.password && errors.password.message}
          />
          <FormInputText
            control={control}
            margin='normal'
            required
            fullWidth
            name='passwordConfirmation'
            label='Repita sua nova senha'
            type='password'
            autoComplete='confirm-password'
            size='medium'
            error={!!errors.passwordConfirmation}
            helperText={!!errors.passwordConfirmation && errors.passwordConfirmation.message}
          />
          <Grid />
        </Grid>
      </Grid>
      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        size='large'
        disabled={isLoading}
        loading={isLoading}
        sx={{
          mt: 5,
          mb: 4,
          textTransform: 'none',
          fontSize: '12pt',
          ':hover': { background: theme.palette.secondary.main },
          height: '54px',
        }}
      >
        Salvar nova senha
      </LoadingButton>
    </Box>
  );
}
