import React from 'react';

import { Badge, IconButton } from '@mui/material';

export const Notification = ({ icon, notificationCount }) => {
  return (
    <>
      <IconButton size='small' aria-label='show notifications' color='inherit'>
        <Badge badgeContent={notificationCount} color='error'>
          {icon}
        </Badge>
      </IconButton>
    </>
  );
};
