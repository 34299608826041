import React, { FC } from 'react';

import {
  EditOutlined as EditIcon,
  VisibilityOutlined as ViewIcon,
  DeleteOutlineOutlined as DeleteIcon,
} from '@mui/icons-material';
import {
  TableBody as MuiTableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { Column } from './index';
import { tableBodyStyles } from './styles';

interface Props {
  keyExtractor: string;
  tableStyle?: 'normal' | 'spaced';
  columns: Array<Column>;
  data: Array<any>;
  emptyRows: number;
  actions?: boolean;
  customActions?: unknown;
  selectOnChange?: (item: any) => void;
  defaultHandleActions?: {
    view?: (row: unknown) => void;
    edit?: (row: unknown) => void;
    delete?: (row: unknown) => void;
  };
  templateActions?: Array<string>;
}
interface Item {
  id: number;
}
export const TableBody: FC<Props> = ({
  keyExtractor,
  tableStyle,
  emptyRows,
  selectOnChange,
  data,
  columns,
  actions,
  customActions,
  templateActions,
  defaultHandleActions,
}) => {
  const theme = useTheme();
  // const iconSize = useMediaQuery(theme.breakpoints.down('md')) ? 'small' : 'large';
  const iconSize = 'small';

  const DEFAULT_ACTIONS = {
    view: (row, handle, index) => (
      <Tooltip key={'action_view'} title='Visualizar'>
        <IconButton onClick={() => handle(row, index)}>
          <ViewIcon fontSize={iconSize} sx={{ color: '#265DC5' }} />
        </IconButton>
      </Tooltip>
    ),

    edit: (row, handle, index) => (
      <Tooltip key={'action_edit'} title='Editar'>
        <IconButton onClick={() => handle(row, index)}>
          <EditIcon fontSize={iconSize} sx={{ color: '#58AA5B' }} />
        </IconButton>
      </Tooltip>
    ),

    delete: (row, handle, index) => (
      <Tooltip key={'action_delete'} title='Excluir'>
        <IconButton onClick={() => handle(row, index)}>
          <DeleteIcon fontSize={iconSize} sx={{ color: '#FF4444' }} />
        </IconButton>
      </Tooltip>
    ),
  };

  return (
    <MuiTableBody sx={tableBodyStyles({ tableStyle, theme })}>
      {data?.map((row, rowIndex) => {
        return (
          <TableRow tabIndex={-1} key={row[keyExtractor]}>
            {columns.map((column) => {
              return (
                <TableCell key={row.id + column.name} align={column.align || 'left'}>
                  {column.custom ? column.custom(row) : row[column.name]}
                </TableCell>
              );
            })}
            {actions !== false && (
              <TableCell
                className='table-button-actions'
                align='center'
                sx={{ [theme.breakpoints.down('md')]: { display: 'flex', alignItems: 'center' } }}
              >
                {templateActions.map((action) => {
                  return customActions && customActions[action]
                    ? customActions[action](row, rowIndex)
                    : DEFAULT_ACTIONS[action](row, defaultHandleActions[action], rowIndex);
                })}
              </TableCell>
            )}
          </TableRow>
        );
      })}
      {/*{emptyRows > 0 && (*/}
      {/*  <TableRow*/}
      {/*    style={{*/}
      {/*      height: 53 * emptyRows,*/}
      {/*      boxShadow: 'unset',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <TableCell colSpan={6} />*/}
      {/*  </TableRow>*/}
      {/*)}*/}
    </MuiTableBody>
  );
};
