import React, { useContext } from 'react';

import {
  HomeOutlined as HomeOutlinedIcon,
  WorkOutlineOutlined as WorkOutlineOutlinedIcon,
  DesignServicesOutlined as DesignServicesOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  GroupOutlined as GroupOutlinedIcon,
} from '@mui/icons-material';

import paths from '~/routes/paths';
import { MenuContext } from '~/store/contexts/MenuContext';

import { MenuItem } from './MenuItem';
import { List, Divider } from './styles';

export const MenuContent: React.FC = () => {
  const { isOpenMenu, drawerWidth } = useContext(MenuContext);

  return (
    <List open={isOpenMenu} drawerWidth={drawerWidth}>
      <MenuItem text='Dashboard' icon={<HomeOutlinedIcon />} path={paths.dashboard} />
      <Divider />
      <MenuItem text='Categoria de Serviços' icon={<WorkOutlineOutlinedIcon />} path={paths.categories} />
      <MenuItem text='Serviços' icon={<DesignServicesOutlinedIcon />} path={paths.services} />
      <Divider />
      <MenuItem text='Usuários' icon={<PersonOutlineOutlinedIcon />} path={paths.users} />
    </List>
  );
};
